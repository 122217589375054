<template>
  <div class="order-edit">
    <div class="col-2 offset-1 button-container">
      <router-link :to="{ name: 'AdminDashboard' }" class="styled-button">Atgal</router-link>
    </div>
    <h1>Užsakymo Redagavimas - ID: {{ id }}</h1>

    <!-- Notification -->
    <div v-if="notification" :class="['notification', notification.type]">
      {{ notification.message }}
    </div>

    <div v-if="order" class="order-form-container">
      <form @submit.prevent="saveOrder">
        <div class="form-group">
        <label for="status">Būsena</label>
        <select v-model="order.status" id="status" class="custom-select" :disabled="order.payment_status === 'waiting_confirmation'">
          <option v-for="status in orderStatuses" :key="status" :value="status">
            {{ status }}
          </option>
        </select>
      </div>


        <div class="form-group">
          <label for="paymentStatus">Apmokėjimo būsena</label>
          <input v-model="order.payment_status" id="paymentStatus" type="text" readonly />
        </div>

        <div class="form-group">
          <label for="created">Sukurta</label>
          <input v-model="order.created" id="created" type="text" disabled />
        </div>

        <div class="form-group">
          <label for="updated">Atnaujinta</label>
          <input v-model="order.updated" id="updated" type="text" disabled />
        </div>

        <div class="form-group">
          <label for="fullName">Vardas Pavardė</label>
          <input v-model="order.full_name" id="fullName" type="text" disabled />
        </div>

        <div class="form-group">
          <label for="email">El. paštas</label>
          <input v-model="order.email" id="email" type="email" disabled />
        </div>

        <div class="form-group">
          <label for="phoneNumber">Telefono numeris</label>
          <input v-model="order.phone_number" id="phoneNumber" type="tel" disabled />
        </div>

        <div class="form-group">
          <label for="shippingAddress">Pristatymo adresas</label>
          <input v-model="order.shipping_address" id="shippingAddress" type="text" disabled />
        </div>

        <div class="form-group">
          <label for="orderTotal">Užsakymo suma</label>
          <input v-model="order.order_total" id="orderTotal" type="number" step="0.01" disabled />
        </div>

        <button type="submit" class="save-button">Išsaugoti</button>
      </form>

      <div class="order-items">
        <h2>Užsakymo Prekės</h2>
        <div v-if="order.items.length" class="items-container">
          <div v-for="item in order.items" :key="item.product_id" class="item-card">
            <h3>{{ item.product_name }}</h3>
            <p><strong>Pardavimo kaina:</strong> {{ item.price }} €</p>
            <p><strong>Savikaina:</strong> {{ item.cost_price }} €</p>
            <p><strong>Produkto source'as:</strong></p> 
            <a :href="item.source" target="_blank" rel="noopener noreferrer">{{ item.source }}</a>
          </div>
        </div>
        <p v-else>Šis užsakymas neturi prekių.</p>
      </div>
    </div>
    <p v-else>Užsakymo duomenys kraunami...</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OrderEditComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      order: null,
      orderStatuses: [],
      notification: null, // To hold success/error messages
    };
  },
  methods: {
    getAuthHeader() {
      const token = localStorage.getItem("adminToken");
      return { Authorization: `Bearer ${token}` };
    },
    async fetchOrder() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/orders/${this.id}`,
          {
            headers: this.getAuthHeader(),
          }
        );
        this.order = response.data;
      } catch (error) {
        this.showNotification("Klaida gaunant užsakymo duomenis.", "error");
      }
    },
    async fetchOrderStatuses() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/orders/statuses`,
          {
            headers: this.getAuthHeader(),
          }
        );
        this.orderStatuses = response.data;
      } catch (error) {
        this.showNotification("Klaida gaunant užsakymo statusus.", "error");
      }
    },
    async saveOrder() {
      try {
        const orderData = {
          status: this.order.status,
          full_name: this.order.full_name,
          email: this.order.email,
          phone_number: this.order.phone_number,
          shipping_address: this.order.shipping_address,
          order_total: this.order.order_total,
        };

        await axios.put(
          `${process.env.VUE_APP_API_BASE_URL}/orders/${this.id}`,
          orderData,
          {
            headers: this.getAuthHeader(),
          }
        );

        this.showNotification("Užsakymo duomenys sėkmingai atnaujinti!", "success");
      } catch (error) {
        this.showNotification("Nepavyko atnaujinti užsakymo duomenų.", "error");
      }
    },
    showNotification(message, type) {
      this.notification = { message, type };

      // Automatically clear the notification after 3 seconds
      setTimeout(() => {
        this.notification = null;
      }, 3000);
    },
  },
  mounted() {
    this.fetchOrderStatuses();
    this.fetchOrder();
  },
};
</script>

<style scoped>
.order-edit {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1,
h2 {
  text-align: center;
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.button-container {
  margin-bottom: 20px;
}

.styled-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.styled-button:hover {
  background-color: #2199b1;
}

.order-form-container {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification {
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.form-group input:focus,
.custom-select:focus {
  outline: none;
  border-color: #2cb6d2;
}

.save-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #2cb6d2;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.save-button:hover {
  background-color: #2199b1;
}

.save-button:active {
  background-color: #1a7d96;
}

.order-items {
  margin-top: 30px;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.item-card {
  flex: 1 1 calc(33% - 15px);
  min-width: 250px;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.item-card h3 {
  margin-bottom: 10px;
  color: #2cb6d2;
}

.item-card p {
  margin: 5px 0;
}
</style>
