<template>
  <div class="carts-container">
    <h1>Visi krepšeliai</h1>
    <div v-if="loading" class="loading">Įkeliama...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="carts.length === 0 && !loading" class="no-carts">Krepšelių nėra.</div>

    <table class="cart-table" v-if="carts.length > 0">
      <thead>
        <tr>
          <th>Sukurimo data</th>
          <th>Krepšelio ID</th>
          <th>Produktai</th>
          <th>Bendras Kainas</th>
          <!-- <th>Veiksmai</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="cart in carts" :key="cart.identifier">
          <td>{{ cart.created }}</td>
          <td>{{ cart.identifier }}</td>
          <td class="cart-products" style="display: flex; flex-direction: row;">
            <div v-for="item in cart.items" :key="item.id" class="cart-product">
              <img :src="item.image" alt="Product Image" class="product-image" />
              <span class="product-name">{{ item.name }}</span>
            </div>
          </td>
          <td>{{ formatPrice(getTotalPrice(cart.items)) }}</td>
          <!-- <td>
            <button @click="viewCart(cart)" class="btn btn-view">Peržiūrėti</button>
            <button @click="deleteCart(cart)" class="btn btn-delete">Ištrinti</button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AllCarts",
  data() {
    return {
      carts: [],
      loading: false,
      error: null,
    };
  },
  methods: {
    async fetchCarts() {
      this.loading = true;
      this.error = null;

      const token = localStorage.getItem("adminToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/carts`,
          { headers }
        );
        this.carts = response.data;
      } catch (error) {
        this.error = "Įvyko klaida, nepavyko gauti krepšelių duomenų.";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatPrice(price) {
      return new Intl.NumberFormat("lt-LT", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    getTotalPrice(items) {
      return items.reduce((total, item) => total + item.price, 0);
    },
    viewCart(cart) {
      console.log("View cart:", cart);
      // Implement view logic
    },
    deleteCart(cart) {
      console.log("Delete cart:", cart);
      // Implement delete logic
    },
  },
  mounted() {
    this.fetchCarts();
  },
};
</script>
<style scoped>
.carts-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.loading,
.error,
.no-carts {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

.cart-table {
  width: 120%;
  border-collapse: collapse;
  margin-top: 20px;
}

.cart-table th,
.cart-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  vertical-align: top;
}

.cart-table th {
  background-color: #2cb6d2;
  color: #fff;
}

.cart-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cart-table tr:hover {
  background-color: #f1f1f1;
}

.cart-products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.cart-product {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.product-name {
  font-size: 14px;
  color: #333;
}

.btn {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-view {
  background-color: #2cb6d2;
  color: #fff;
}

.btn-view:hover {
  background-color: #249ab6;
}

.btn-delete {
  background-color: #e74c3c;
  color: #fff;
}

.btn-delete:hover {
  background-color: #c0392b;
}
</style>
