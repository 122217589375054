<template>
  <div class="preset-add-form">
    <h2 class="product-title">Pasirink subkategorija</h2>

    <div class="message-container">
      <div v-if="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <div v-if="successMessage" class="alert alert-success">
        {{ successMessage }}
      </div>
    </div>

    <div class="form-group">
      <select
        v-model="selectedSubcategory"
        id="subCategoryPrompt"
        class="form-control"
        @change="fetchPresets"
      >
        <option disabled value="">Pasirinkite subkategoriją</option>
        <optgroup
          v-for="(subcategories, categoryName) in groupedSubcategories"
          :label="categoryName"
          :key="categoryName"
        >
          <option
            v-for="subcategory in subcategories"
            :key="subcategory.id"
            :value="subcategory.id"
          >
            {{ subcategory.name }}
          </option>
        </optgroup>
      </select>
    </div>

    <!-- Side-by-Side Layout for General Details and Attributes -->
    <div v-if="selectedSubcategory" class="presets-container">
      <!-- General Details Section -->
      <div class="preset-section">
        <h3 class="subtitle">Bendrosios charakteristikos</h3>

        <div
          v-for="(preset, index) in generalDetailsPresets"
          :key="index"
          class="preset-field"
        >
          <div class="form-group">
            <label :for="'fieldName-' + index">Pavadinimas</label>
            <input
              v-model="preset.fieldName"
              :id="'fieldName-' + index"
              type="text"
              class="form-control input"
              required
            />
          </div>

          <div class="form-group">
            <label :for="'defaultValue-' + index">Default verte</label>
            <input
              v-model="preset.defaultValue"
              :id="'defaultValue-' + index"
              type="text"
              class="form-control input"
            />
          </div>

          <button
            @click="removePreset(index, 'generalDetail')"
            type="button"
            class="btn-delete"
          >
            Remove Field
          </button>
        </div>

        <button
          @click="addPresetField('generalDetail')"
          type="button"
          class="styled-button"
        >
          Add Another General Detail
        </button>
      </div>

      <!-- Attributes Section -->
      <div class="preset-section">
        <h3 class="subtitle">Techniniai duomenys</h3>

        <div
          v-for="(preset, index) in attributePresets"
          :key="index"
          class="preset-field"
        >
          <div class="form-group">
            <label :for="'fieldName-' + index">Pavadinimas</label>
            <input
              v-model="preset.fieldName"
              :id="'fieldName-' + index"
              type="text"
              class="form-control input"
              required
            />
          </div>

          <div class="form-group">
            <label :for="'defaultValue-' + index">Default verte</label>
            <input
              v-model="preset.defaultValue"
              :id="'defaultValue-' + index"
              type="text"
              class="form-control input"
            />
          </div>

          <button
            @click="removePreset(index, 'attribute')"
            type="button"
            class="btn-delete"
          >
            Remove Field
          </button>
        </div>

        <button
          @click="addPresetField('attribute')"
          type="button"
          class="styled-button"
        >
          Add Another Attribute
        </button>
      </div>
    </div>

    <!-- Save Presets Button -->
    <div class="button-container mt-3">
      <button
        @click="submitPresets"
        type="button"
        style="
          margin-top: 1rem;
          margin-bottom: 1rem;
          background-color: green !important;
        "
        class="styled-button"
      >
        Save All Presets
      </button>
    </div>

    <!-- Existing Presets for Selected Subcategory -->
    <div
      v-if="
        existingPresets.generalDetails.length ||
        existingPresets.attributes.length
      "
      class="presets-container mt-4"
    >
      <!-- Existing General Details -->
      <div class="preset-section">
        <h3 class="subtitle">Dabartines bendrosios charakteristikos</h3>
        <div
          v-if="existingPresets.generalDetails.length === 0"
          class="preset-card"
        >
          No General Details
        </div>
        <div
          v-for="preset in existingPresets.generalDetails"
          :key="preset.id"
          class="preset-card"
        >
          <div class="preset-info">
            <div
              v-if="preset.editing"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>
                <input
                  v-model="preset.fieldName"
                  type="text"
                  class="form-control input"
                  placeholder="Pavadinimas"
                />
                <input
                  v-model="preset.defaultValue"
                  type="text"
                  class="form-control input"
                  placeholder="Default verte"
                />
              </div>
              <div>
                <button
                  @click="savePreset(preset)"
                  class="styled-button btn-sm"
                >
                  Save
                </button>
                <button
                  @click="preset.editing = false"
                  style="margin-left: 1rem"
                  class="btn-delete btn-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div
              v-else
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>
                <span
                  ><strong>Pavadinimas:</strong> {{ preset.fieldName }}</span
                >
                <span
                  ><strong>Default verte:</strong>
                  {{ preset.defaultValue || "N/A" }}</span
                >
              </div>
              <div>
                <button
                  @click="editPreset(preset)"
                  class="styled-button btn-sm"
                >
                  Edit
                </button>
                <button
                  @click="deletePreset(preset.id)"
                  style="margin-left: 1rem"
                  class="btn-delete btn-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Existing Attributes -->
      <div class="preset-section">
        <h3 class="subtitle">Dabartiniai techniniai duomenys</h3>
        <div v-if="existingPresets.attributes.length === 0" class="preset-card">
          No Attributes
        </div>
        <div
          v-for="preset in existingPresets.attributes"
          :key="preset.id"
          class="preset-card"
        >
          <div class="preset-info">
            <div
              v-if="preset.editing"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>
                <input
                  v-model="preset.fieldName"
                  type="text"
                  class="form-control input"
                  placeholder="Pavadinimas"
                />
                <input
                  v-model="preset.defaultValue"
                  type="text"
                  class="form-control input"
                  placeholder="Default verte"
                />
              </div>
              <div>
                <button
                  @click="savePreset(preset)"
                  class="styled-button btn-sm"
                >
                  Save
                </button>
                <button
                  @click="preset.editing = false"
                  style="margin-left: 1rem"
                  class="btn-delete btn-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div
              v-else
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>
                <span
                  ><strong>Pavadinimas:</strong> {{ preset.fieldName }}</span
                >
                <span
                  ><strong>Default verte:</strong>
                  {{ preset.defaultValue || "N/A" }}</span
                >
              </div>
              <div>
                <button
                  @click="editPreset(preset)"
                  class="styled-button btn-sm"
                >
                  Edit
                </button>
                <button
                  @click="deletePreset(preset.id)"
                  style="margin-left: 1rem"
                  class="btn-delete btn-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "PresetComponent",
  data() {
    return {
      subcategories: [],
      selectedSubcategory: null,
      selectedSubcategoryName: "",
      generalDetailsPresets: [],
      attributePresets: [],
      existingPresets: {
        generalDetails: [],
        attributes: [],
      },
      successMessage: "",
      errorMessage: "",
    };
  },
  computed: {
    groupedSubcategories() {
      return this.subcategories.reduce((acc, subcategory) => {
        const categoryName = subcategory.category_name; // Adjust this based on your data structure
        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }
        acc[categoryName].push(subcategory);
        return acc;
      }, {});
    },
  },
  methods: {
    async fetchSubcategories() {
      try {
        const response = await axios.get(`${API_BASE_URL}/subcategories`);
        this.subcategories = response.data;
      } catch (error) {
        this.errorMessage = "Failed to load subcategories";
      }
    },
    addPresetField(type) {
      if (type === "generalDetail") {
        this.generalDetailsPresets.push({
          fieldName: "",
          fieldType: "generalDetail",
          defaultValue: "",
        });
      } else if (type === "attribute") {
        this.attributePresets.push({
          fieldName: "",
          fieldType: "attribute",
          defaultValue: "",
        });
      }
    },
    removePreset(index, type) {
      if (type === "generalDetail") {
        this.generalDetailsPresets.splice(index, 1);
      } else if (type === "attribute") {
        this.attributePresets.splice(index, 1);
      }
    },
    async submitPresets() {
      if (!this.selectedSubcategory) {
        this.errorMessage = "Please select a subcategory";
        return;
      }

      const presets = [...this.generalDetailsPresets, ...this.attributePresets];
      try {
        await axios.post(
          `${API_BASE_URL}/subcategory/${this.selectedSubcategory}/preset/batch`,
          {
            presets,
          }
        );
        this.successMessage = "Presets added successfully!";
        this.clearForm();
        this.fetchPresets();
      } catch (error) {
        this.errorMessage = "Failed to add presets";
      }
    },
    async fetchPresets() {
      if (!this.selectedSubcategory) return;

      try {
        const response = await axios.get(
          `${API_BASE_URL}/subcategory/${this.selectedSubcategory}/presets`
        );
        const presets = response.data;
        this.existingPresets = {
          generalDetails: presets
            .filter((preset) => preset.fieldType === "generalDetail")
            .map((preset) => ({ ...preset, editing: false })),
          attributes: presets
            .filter((preset) => preset.fieldType === "attribute")
            .map((preset) => ({ ...preset, editing: false })),
        };
        this.selectedSubcategoryName =
          this.subcategories.find((sub) => sub.id === this.selectedSubcategory)
            ?.name || "";
      } catch (error) {
        this.errorMessage =
          "Failed to load presets for the selected subcategory";
      }
    },
    clearForm() {
      this.generalDetailsPresets = [];
      this.attributePresets = [];
      this.selectedSubcategory = null;
      this.successMessage = "";
      this.errorMessage = "";
    },
    editPreset(preset) {
      preset.editing = true;
    },
    async savePreset(preset) {
      try {
        await axios.put(`${API_BASE_URL}/subcategory/preset/${preset.id}`, {
          fieldName: preset.fieldName,
          fieldType: preset.fieldType,
          defaultValue: preset.defaultValue,
        });

        this.successMessage = "Preset updated successfully!";
        preset.editing = false;
        this.fetchPresets();
      } catch (error) {
        this.errorMessage = "Failed to update preset";
      }
    },
    async deletePreset(id) {
      try {
        await axios.delete(`${API_BASE_URL}/subcategory/preset/${id}`);
        this.successMessage = "Preset deleted successfully!";
        this.fetchPresets();
      } catch (error) {
        this.errorMessage = "Failed to delete preset";
      }
    },
  },
  async mounted() {
    this.fetchSubcategories();
  },
};
</script>

<style scoped>
.preset-field {
  display: flex;
  justify-content: space-around;
}

.preset-add-form {
  width: 100%;
  padding: 20px;
  background-color: #f4f7f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Side-by-Side Layout */
.presets-container {
  display: flex;
  gap: 2rem;
}

.preset-section {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.preset-card {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #fff;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.preset-info {
  /* display: flex; */
  gap: 10px;
  align-items: center;
  font-weight: 500;
}

.preset-actions {
  display: flex;
  gap: 10px;
}

.styled-button {
  background-color: #2cb6d2;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9em;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.styled-button:hover {
  background-color: #2396ae;
  transform: translateY(-2px);
}

.btn-delete {
  background-color: #dc3545;
  color: #ffffff;
  font-size: 0.9em;
  padding: 5px 5px;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s;
  height: 30px !important;
  margin-top: 0.7rem;
}

.input {
  padding: 5px !important;
}

.btn-delete:hover {
  background-color: #c82333;
}

.message-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  height: 2rem;
}

.alert {
  max-width: 500px;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
</style>
