<template>
  <div class="row product-edit-container">
    <div class="col-2 offset-1 button-container">
      <router-link :to="{ name: 'AdminDashboard' }" class="styled-button">Atgal</router-link>
      <a @click="goToProductPage" class="styled-button mt-3">Peržiūrėti pardėj</a>
      <a @click="duplicateProduct" class="styled-button mt-3" style="background-color: orange;">Padaryti produkto duplikata</a>
    </div>
    <div v-if="formData" class="col-8 form-content">
      <h2 class="product-title">Atnaujinti produktą: {{ formData.name }}</h2>

      <div v-if="successMessage" class="alert alert-success">
        {{ successMessage }}
      </div>
      <div v-if="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>

      <form @submit.prevent="updateProduct" class="update-form">
        <div class="visibility-toggle">
          <label class="visibility-label">
            <span>Produkto matomas</span>
            <input type="checkbox" v-model="formData.isVisible" class="visibility-checkbox" />
          </label>
        </div>

        <a style="margin-bottom: 1rem" :href="formData.source_link" target="_blank" rel="noopener noreferrer">{{
          formData.source_link }}</a>
        <div class="form-group" style="margin-top: 1rem">
          <label for="name" class="form-label">Produkto sourc'as</label>
          <input v-model="formData.source_link" type="text" id="source_link" class="form-control" required />
        </div>

        <div class="form-group">
          <label for="name" class="form-label">Produkto pavadinimas</label>
          <input v-model="formData.name" type="text" id="name" class="form-control" required />
        </div>

        <div class="form-group">
          <label for="name" class="form-label">Produkto EAN kodas/BAR kodas</label>
          <input v-model="formData.eanCode" type="text" id="eanCode" class="form-control" required />
        </div>

        <div class="form-group">
          <label for="additionalEANcodes" class="form-label">Papildomi EAN/BAR kodai</label>
          <div class="char-table-data">
            <div v-for="(code, index) in formData.additionalEANcodes" :key="index" class="char-table-item">
              <input type="number" v-model="formData.additionalEANcodes[index]" class="form-control"
                placeholder="EAN/BAR kodas" />
              <button type="button" @click="removeEANCode(index)" class="btn-delete">
                Pašalinti
              </button>
            </div>
            <button type="button" @click="addEANCode" class="btn-add">
              Pridėti EAN kodą
            </button>
          </div>
        </div>

        <!-- Price, MSRP -->
        <div class="form-group prices-group">
          <div class="price-item">
            <label for="price" class="form-label">Kaina</label>
            <input v-model="formData.price" type="number" id="price" class="form-control" step="0.01" required />
          </div>
          <div class="price-item">
            <label for="msrp" class="form-label">MSRP</label>
            <input v-model="formData.msrp" type="number" id="msrp" class="form-control" step="0.01" required />
          </div>
          <div class="price-item">
            <label for="msrp" class="form-label">Cost_price</label>
            <input v-model="formData.cost_price" type="number" id="msrp" class="form-control" step="0.01" required />
          </div>
        </div>

        <!-- Condition -->
        <div class="form-group">
          <label for="condition" class="form-label">Būklė</label>
          <select v-model="formData.condition.id" id="condition" class="form-control" required>
            <option disabled value="">Pasirinkite būklę</option>
            <option v-for="condition in conditions" :key="condition.id" :value="condition.id">
              {{ condition.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="guruante" class="form-label">Garantinis</label>
          <select v-model="formData.returnPeriod" id="guruante" class="form-control" required>
            <option v-for="month in months" :key="month" :value="month">
              {{ month }} mėnesiai
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="description" class="form-label">Papildomos detales (optional)</label>
          <textarea placeholder="Produktas neturi pakuotes/neveikia kazkokia funkcija" v-model="formData.note"
            id="description" class="form-control" rows="2"></textarea>
        </div>

        <!-- Subcategory -->
        <div class="form-group">
          <label for="subCategory" class="form-label">Subkategorija</label>
          <select v-model="formData.category.subCategory_id" id="subCategory" class="form-control" required>
            <option disabled value="">Pasirinkite subkategoriją</option>
            <option v-for="subcategory in subCategories" :key="subcategory.id" :value="subcategory.id">
              {{ subcategory.name }}
            </option>
          </select>
        </div>

        <!-- Description -->
        <div class="form-group">
          <label for="description" class="form-label">Aprašymas <span style="color: red;">Sito nebepildom judam prie antrinio aprasymo ji galima formatuoti</span></label>
          <textarea v-model="formData.mainDescription" id="description" class="form-control" rows="6"></textarea>
        </div>

        <div class="form-group">
          <label for="secondaryDescription" class="form-label">Antrinis aprašymas</label>
          <div id="secondaryDescription" ref="quillEditor" style="
              min-height: 150px;
              border: 1px solid #ced4da;
              border-radius: 5px;
            "></div>
        </div>

        <!-- General Details -->
        <div class="form-group">
          <label for="generalDetails" class="form-label">Bendros detalės</label>
          <div class="char-table-data">
            <div v-for="(item, index) in formData.generalDetails" :key="index" class="char-table-item">
              <input v-model="item.feature" class="form-control" placeholder="Ypatybė" />
              <input v-model="item.value" class="form-control" placeholder="Vertė" />
              <button type="button" @click="removeCharacteristic(index)" class="btn-delete">
                Pašalinti
              </button>
            </div>
            <button type="button" @click="addCharacteristic" class="btn-add">
              Pridėti ypatybę
            </button>
          </div>
        </div>

        <!-- Attributes -->
        <div class="form-group">
          <label for="attributes" class="form-label">Atributai</label>
          <div class="char-table-data">
            <div v-for="(item, index) in formData.attributes" :key="index" class="char-table-item">
              <input v-model="item.attribute_name" class="form-control" placeholder="Atributo pavadinimas" />
              <input v-model="item.attribute_value" class="form-control" placeholder="Atributo vertė" />
              <button type="button" @click="removeSpecification(index)" class="btn-delete">
                Pašalinti
              </button>
            </div>
            <button type="button" @click="addSpecification" class="btn-add">
              Pridėti atributą
            </button>
          </div>
        </div>

        <!-- Images -->
        <div class="form-group">
          <label for="images" class="form-label">Nuotraukos</label>
          <div class="char-table-data">
            <div v-for="(image, index) in formData.images" :key="index" class="char-table-item">
              <div style="display: flex; flex-direction: column; width: 90%">
                <input v-model="image.image_url" class="form-control" placeholder="Nuotraukos URL"
                  style="width: 90%; margin-bottom: 10px" />
                CDN url'as
                <input v-model="image.cdn_url" class="form-control" disabled style="width: 90%" />
              </div>
              <label class="visibility-label">
                <span>Pagrindinis</span>
                <input type="checkbox" v-model="image.is_main_image" class="visibility-checkbox" />
              </label>
              <button type="button" @click="removeImage(index)" class="btn-delete">
                Pašalinti
              </button>
            </div>
            <button type="button" @click="addImage" class="btn-add">
              Pridėti nuotrauką
            </button>
          </div>
        </div>

        <button type="submit" class="btn-submit">Atnaujinti produktą</button>
      </form>
    </div>
    <div v-else>
      <p>Kraunama produkto informacija...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { isTokenExpired } from "@/utility/jwtUtils";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const CLIENT_FRONT_URL = process.env.VUE_APP_CLIENT_FRONT_URL;
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  name: "ProductEdit",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productData: null,
      formData: {
        isVisible: false,
        name: "",
        ean: "",
        additionalEANcodes: [],
        price: "",
        source_link: "",
        msrp: "",
        cost_price: "",
        condition: { id: null },
        returnPeriod: "",
        note: "",
        category: { subCategory_id: null },
        mainDescription: "",
        secondaryDescription: "",
        generalDetails: [],
        attributes: [],
        images: [],
        existingImageCdnURLS: [],
      },
      conditions: [],
      subCategories: [],
      successMessage: null,
      errorMessage: null,
      months: Array.from({ length: 12 }, (_, i) => i + 1),
    };
  },

  methods: {
    initQuill() {
      this.quill = new Quill(this.$refs.quillEditor, {
        theme: "snow",
        placeholder: "Įveskite antrinį aprašymą...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], // Formatting options
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            ["link"], // Links
            ["clean"], // Clear formatting
          ],
        },
      });

      // Sync Quill content with formData
      this.quill.on("text-change", () => {
        this.formData.secondaryDescription = this.quill.root.innerHTML;
      });
    },

    initializeQuillWithData() {
      this.$nextTick(() => {
        this.initQuill();
        if (this.formData.secondaryDescription) {
          this.quill.root.innerHTML = this.formData.secondaryDescription;
        }
      });
    },

    addEANCode() {
      this.formData.additionalEANcodes.push(""); // Add an empty string to the array
    },

    removeEANCode(index) {
      this.formData.additionalEANcodes.splice(index, 1); // Remove the code at the specified index
    },
    goToProductPage() {
      const url = `${CLIENT_FRONT_URL}/product/${this.formData.id}`;
      window.location.href = url;
    },
    duplicateProduct() {

      const token = localStorage.getItem("adminToken");
      if (isTokenExpired(token)) {
        return;
      }

      const url = `${API_BASE_URL}/product/copy/${this.formData.id}`;
    
      axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        console.log("Product duplicated successfully:", response.data);
        alert("Produktas sėkmingai dubliuotas!"); // Show success message
      })
      .catch(error => {
        console.error("Error duplicating product:", error.response?.data || error.message);
        alert("Nepavyko dubliuoti produkto."); // Show error message
      });
    },
    async fetchConditions() {
      try {
        const response = await axios.get(`${API_BASE_URL}/conditions`);
        this.conditions = response.data;
      } catch (error) {
        console.error("Error fetching conditions:", error);
      }
    },
    async fetchSubCategories() {
      try {
        const response = await axios.get(`${API_BASE_URL}/subcategories`);
        this.subCategories = response.data;
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    fetchProductData() {
      const token = localStorage.getItem("adminToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      axios
        .get(`${API_BASE_URL}/admin/product/${this.id}`, { headers })
        .then((response) => {
          this.productData = response.data;
          this.formData = {
            ...response.data,
            condition: { id: response.data.condition.id },
            additionalEANcodes: response.data.additionalEANCodes,
            returnPeriod: response.data.returnPeriod,
            note: response.data.note || null,
            isVisible: response.data.status === 1,
          };
          this.initializeQuillWithData();
          console.log("formdata: ", this.formData);
        })
        .catch((error) => {
          this.errorMessage = "Klaida įkeliant produkto duomenis." + error;
          this.clearMessages();
        });
    },

    updateProduct() {
      const payload = {
        name: this.formData.name,
        ean: this.formData.eanCode,
        additionalEANcodes: this.formData.additionalEANcodes,
        price: this.formData.price,
        source_link: this.formData.source_link,
        MSRP: this.formData.msrp,
        cost_price: this.formData.cost_price,
        condition: { id: this.formData.condition.id },
        subCategory: { id: this.formData.category.subCategory_id },
        mainDescription: this.formData.mainDescription,
        secondaryDescription: this.formData.secondaryDescription,
        returnPeriod: this.formData.returnPeriod,
        note: this.formData.note,
        generalDetails: this.formData.generalDetails,
        attributes: this.formData.attributes,
        images: this.formData.images.map((image) => ({
          image_url: image.image_url,
          cdn_image_url: image.cdn_url, // Include the existing CDN URL
          is_main_image: image.is_main_image,
        })),
        status: this.formData.isVisible ? 1 : 3, // Use isVisible to set status
      };

      const token = localStorage.getItem("adminToken");
      if (isTokenExpired(token)) {
        return;
      }
      axios
        .put(`${API_BASE_URL}/product/${this.id}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.successMessage = "Produktas sėkmingai atnaujintas!";
          this.clearMessages();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((error) => {
          this.errorMessage =
            "Klaida atnaujinant produktą. Bandykite dar kartą. " +
            error.response.data.error;
          this.clearMessages();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    clearMessages() {
      setTimeout(() => {
        this.successMessage = null;
        this.errorMessage = null;
      }, 3000);
    },
    addCharacteristic() {
      this.formData.generalDetails.push({ feature: "", value: "" });
    },
    addSpecification() {
      this.formData.attributes.push({
        attribute_name: "",
        attribute_value: "",
      });
    },
    removeCharacteristic(index) {
      this.formData.generalDetails.splice(index, 1);
    },
    removeSpecification(index) {
      this.formData.attributes.splice(index, 1);
    },
    addImage() {
      this.formData.images.push({ image_url: "", is_main_image: false });
    },
    removeImage(index) {
      this.formData.images.splice(index, 1);
    },
  },
  created() {
    this.fetchProductData();
    this.fetchConditions();
    this.fetchSubCategories();
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 6rem;
  width: 15%;
}

.styled-button {
  padding: 10px 20px;
  background-color: #2cb6d2;
  color: #ffffff;
  font-weight: bold;
  font-size: 1em;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
}

.styled-button:hover {
  background-color: #2396ae;
  transform: translateY(-2px);
}

.mt-3 {
  margin-top: 1rem;
}

.product-edit-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f4f7f9;
  border-radius: 8px;
  display: flex;
  gap: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.price-item {
  text-align: left;
  width: 28%;
}

.prices-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}

.form-label {
  font-size: 1.2em;
  font-weight: bold;
  color: #1e262d;
  margin-bottom: 0.5rem;
  text-align: left;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  font-size: 1em;
  text-align: left;
}

.btn-submit {
  background-color: #28a745;
  color: #ffffff;
  padding: 12px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  margin-top: 1.5rem;
}

.alert {
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.char-table-data {
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.char-table-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.btn-add,
.btn-delete {
  padding: 8px;
  font-size: 0.85rem;
  border: none;
  border-radius: 5px;
}

.btn-add {
  background-color: #2cb6d2;
  color: white;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}

/* Container for visibility toggle */
.visibility-toggle {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Label styling */
.visibility-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  gap: 10px;
}

/* Custom checkbox styling */
.visibility-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #ddd;
  border: 2px solid #2cb6d2;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.visibility-checkbox:checked {
  background-color: #2cb6d2;
  border-color: #2396ae;
}

.visibility-checkbox:checked::after {
  content: "✓";
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visibility-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(44, 182, 210, 0.3);
}
</style>
