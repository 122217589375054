<template>
  <div class="all-orders">
    <h1>Visi Užsakymai</h1>
    <table v-if="orders.length" class="orders-table">
      <thead>
        <tr>
          <th>Užsakymo ID</th>
          <th>Būsena</th>
          <th>Apmokėjimo būsena</th>
          <th>Sukurta</th>
          <th>Atnaujinta</th>
          <th>Vardas Pavardė</th>
          <th>El. paštas</th>
          <th>Telefono numeris</th>
          <th>Pristatymo adresas</th>
          <th>Užsakymo suma</th>
          <th>Veiksmai</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id" :class="rowClass(order)">
          <td>{{ order.id }}</td>
          <td>
            {{
              order.payment_status === "waiting_confirmation"
                ? ""
                : order.status
            }}
          </td>
          <td>{{ order.payment_status }}</td>
          <td>{{ formatDate(order.created) }}</td>
          <td>{{ formatDate(order.updated) }}</td>
          <td>{{ order.full_name }}</td>
          <td>{{ order.email }}</td>
          <td>{{ order.phone_number }}</td>
          <td>{{ order.shipping_address }}</td>
          <td>{{ order.order_total.toFixed(2) }} €</td>
          <td>
            <button class="edit-button" @click="editOrder(order.id)">
              Redaguoti
            </button>
            <button class="delete-button" @click="confirmDeleteOrder(order.id)">
              Ištrinti
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>Užsakymų nerasta.</p>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal">
        <h3>Ar tikrai norite ištrinti šį užsakymą?</h3>
        <div class="modal-buttons">
          <button class="confirm-delete" @click="deleteOrder">Taip</button>
          <button class="cancel-delete" @click="showDeleteModal = false">
            Atšaukti
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AllOrdersComponent",
  data() {
    return {
      orders: [],
      showDeleteModal: false,
      orderIdToDelete: null, // Track order ID to delete
    };
  },
  methods: {
    getAuthHeader() {
      const token = localStorage.getItem("adminToken");
      return { Authorization: `Bearer ${token}` };
    },
    async fetchOrders() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/admin/orders`,
          {
            headers: this.getAuthHeader(),
          }
        );
        this.orders = response.data;
      } catch (error) {
        console.error("Klaida gaunant užsakymus:", error);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "Neatnaujinta";

      try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Neteisinga data";

        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "Europe/Vilnius",
        };

        return date.toLocaleDateString("lt-LT", options);
      } catch (error) {
        return "Neteisinga data";
      }
    },
    rowClass(order) {
      if (order.status === "canceled") {
        return "canceled";
      }

      if(order.status === "completed"){
        return "completed_order";
      }

      if(order.status === "pending" && order.payment_status === "confirmed"){
        return "pending_order";
      }

      switch (order.payment_status) {
        case "waiting_confirmation":
          return "waiting-confirmation";
        case "reserved":
          return "reserved";
        case "confirmed":
          return "confirmed";
        case "completed":
          return "completed";
        case "finalized":
          return "finalized";
        default:
          return "";
      }
    },
    editOrder(id) {
      // Redirect to the order details page for editing
      this.$router.push({ name: "OrderEdit", params: { id } });
    },
    confirmDeleteOrder(orderId) {
      this.showDeleteModal = true;
      this.orderIdToDelete = orderId;
    },
    async deleteOrder() {
      try {
        await axios.delete(
          `${process.env.VUE_APP_API_BASE_URL}/orders/${this.orderIdToDelete}`,
          {
            headers: this.getAuthHeader(),
          }
        );
        this.orders = this.orders.filter(
          (order) => order.id !== this.orderIdToDelete
        );
        this.showDeleteModal = false;
        this.orderIdToDelete = null;
      } catch (error) {
        console.error("Klaida ištrinant užsakymą:", error);
      }
    },
  },
  mounted() {
    this.fetchOrders();
  },
};
</script>

<style scoped>
.all-orders {
  padding: 20px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.orders-table th {
  background-color: #2cb6d2;
  color: white;
  text-align: left;
}

.orders-table .waiting-confirmation {
  background-color: #ffffe0;
  /* Light yellow */
}

.orders-table .reserved {
  background-color: #add8e6;
  /* Light blue */
}

.orders-table .confirmed {
  background-color: #48d348;
  /* Light green */
}
.orders-table .finalized {
  background-color: #90ee90;
  /* Darker green */
}

.orders-table .canceled {
  background-color: #ffcccc !important; /* Light red */
  color: black !important; /* Dark red text */
}

button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  font-weight: bold;
}

.edit-button {
  background-color: #2cb6d2;
  color: white;
}

.edit-button:hover {
  background-color: #2199b1;
}

.delete-button {
  background-color: #f08080;
  color: white;
}

.delete-button:hover {
  background-color: #cc6666;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;
}

.modal h3 {
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
}

.confirm-delete {
  background-color: #d9534f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
}

.confirm-delete:hover {
  background-color: #c9302c;
}

.cancel-delete {
  background-color: #5bc0de;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
}

.completed_order{
  background-color: rgb(12, 165, 12);
}
.pending_order {
  background-color: #d69927; /* Light blue background */
  color: white; /* White text for contrast */
  animation: blink 1.5s infinite alternate; /* Optional: Blinking effect */
}

/* Optional blinking effect to grab attention */
@keyframes blink {
  0% { background-color: #fa6007; }
  100% { background-color: #c25801; } /* Darker blue */
}

.cancel-delete:hover {
  background-color: #31b0d5;
}
</style>
